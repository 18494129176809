import bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import nav from './components/nav/nav.js';

const ViewportObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
        entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
    });
});
document.querySelectorAll('[data-inviewport]').forEach(el => {
    ViewportObserver.observe(el, {});
});